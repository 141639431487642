.link {
  text-decoration: underline;
  text-decoration-color: var(--color-highlight);
  transition: color 150ms $ease-fade, text-decoration-color 150ms $ease-fade;
  text-decoration-thickness: em(1);
  text-underline-offset: em(2);

  @media ($min-ms) {
    text-underline-offset: em(1);
  }

  &:hover,
  &:focus {
    color: var(--color-highlight);
    text-decoration-color: var(--color-highlight-hover);
  }

  // Extend click area
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    position: absolute;
    padding: rem(7) 0;
    top: rem(-7);
    left: 0;
  }

  // Make text selectable
  span {
    position: relative;
    z-index: 1;
  }
}
