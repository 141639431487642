.hl {
  --hl-font-size: 1.625rem;
  --hl-margin-bottom: 1.625em;
  --hl-max-width: #{rem(580)};

  @media ($min-m) {
    --hl-font-size: 2rem;
    --hl-margin-bottom: 1.375em;
    --hl-max-width: #{rem(720)};
  }

  font-size: calc(var(--hl-font-size) * var(--scale));
  margin-bottom: var(--hl-margin-bottom);
  max-width: calc(var(--hl-max-width) * var(--scale));

  // Lines
  > span {
    display: block;
  }

  @media ($max-ms) {
    > span + span {
      margin-top: 0.75em;
    }
  }
}

.hl__highlight {
  color: var(--color-highlight);
}
