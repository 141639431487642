@import 'base/mixins';
@import 'base/webfonts';
@import 'base/variables';
@import 'base/defaults';

@import 'components/hl';
@import 'components/link';

html {
  font-family: 'Munken Sans Web', sans-serif;
  font-size: 1em;
  line-height: 1.45;
  letter-spacing: -0.02em;
  color: var(--color-text);
  background-color: var(--color-bg-back);
}

body {
  --body-font-size: 1.25rem;
  --border-width: 0.5rem;
  --padding-x: 1.75rem;
  --padding-y: 2.5rem;
  --scale: 1;

  @media ($min-ms) {
    --border-width: 0.75rem;
    --padding-x: 4.5rem;
    --padding-y: 4.5rem;
  }

  @media ($min-ml) {
    --scale: 1.125;
  }

  @media ($min-l) {
    --scale: 1.25;
  }

  @media ($min-xl) {
    --scale: 1.5;
  }

  font-size: calc(var(--body-font-size) * var(--scale));
  min-height: 100vh;
  padding: calc(var(--padding-y) * var(--scale))
    calc(var(--padding-x) * var(--scale));
  position: relative;

  // BG
  position: relative; // necessary for correct bg position

  &::after {
    content: '';
    background-color: var(--color-bg-front);
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    bottom: var(--border-width);
    right: var(--border-width);
  }
}

main {
  position: relative;
  z-index: 1;
}
