// Breakpoints
$s: em(480);
$ms: em(720);
$m: em(960);
$ml: em(1280);
$l: em(1440);
$xl: em(1680);

$max-s: 'max-width: #{$s - (1/16)}';
$min-s: 'min-width: #{$s}';
$max-ms: 'max-width: #{$ms - (1/16)}';
$min-ms: 'min-width: #{$ms}';
$max-m: 'max-width: #{$m - (1/16)}';
$min-m: 'min-width: #{$m}';
$max-ml: 'max-width: #{$ml - (1/16)}';
$min-ml: 'min-width: #{$ml}';
$max-l: 'max-width: #{$l - (1/16)}';
$min-l: 'min-width: #{$l}';
$max-xl: 'max-width: #{$xl - (1/16)}';
$min-xl: 'min-width: #{$xl}';

// Colors
$color-text: (hsl(0, 0%, 15%), hsl(0, 0%, 85%));
$color-bg-front: (hsl(0, 0%, 100%), hsl(236, 15%, 15%));
$color-bg-back: (hsl(0, 0%, 96%), hsl(236, 15%, 12%));
$color-highlight: (hsl(246, 100%, 58%), hsl(236, 100%, 70%));
$color-highlight-hover: (hsl(246, 100%, 89%), hsl(0, 0%, 85%));

:root {
  --color-text: #{nth($color-text, 1)};
  --color-bg-front: #{nth($color-bg-front, 1)};
  --color-bg-back: #{nth($color-bg-back, 1)};
  --color-highlight: #{nth($color-highlight, 1)};
  --color-highlight-hover: #{nth($color-highlight-hover, 1)};

  @media (prefers-color-scheme: dark) {
    --color-text: #{nth($color-text, 2)};
    --color-bg-front: #{nth($color-bg-front, 2)};
    --color-bg-back: #{nth($color-bg-back, 2)};
    --color-highlight: #{nth($color-highlight, 2)};
    --color-highlight-hover: #{nth($color-highlight-hover, 2)};
  }
}

// Use display-p3 color when supported – currently only Safari on Mac and iOS.
// Safari 13 does support p3 color but not in combination with color transitions.
// So only Safari 14 and newer by detecting image-set + gradient support
@supports (color: color(display-p3 1 1 1)) and
  (background-image: image-set(linear-gradient(#000, #fff) 1x)) {
  :root {
    --color-text: #{p3(nth($color-text, 1))};
    --color-bg-front: #{p3(nth($color-bg-front, 1))};
    --color-bg-back: #{p3(nth($color-bg-back, 1))};
    --color-highlight: #{p3(nth($color-highlight, 1))};
    --color-highlight-hover: #{p3(nth($color-highlight-hover, 1))};

    @media (prefers-color-scheme: dark) {
      --color-text: #{p3(nth($color-text, 2))};
      --color-bg-front: #{p3(nth($color-bg-front, 2))};
      --color-bg-back: #{p3(nth($color-bg-back, 2))};
      --color-highlight: #{p3(nth($color-highlight, 2))};
      --color-highlight-hover: #{p3(nth($color-highlight-hover, 2))};
    }
  }
}

// Easings
$ease-fade: cubic-bezier(0.25, 0, 0.25, 1);
