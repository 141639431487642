html {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: no-common-ligatures;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1 {
  margin: 0;
}

h1 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
}

// Hide outline without degrading a11y,
// High Contrast Mode should still work
a {
  &:focus:not(:focus-visible) {
    outline-color: transparent;
    outline-style: solid; // overwrite outline:auto in Blink
  }
}

// A11y
*,
*::before,
*::after {
  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}
